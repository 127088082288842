import React from "react";
import Cheques from "../../components/Cheques";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Network from "../../components/Network";
import Pitch from "../../components/Pitch";
import PortfolioCarousel from "../../components/PortfolioCarousel";
import RevSlider from "../../components/RevSlider";
import Targeting from "../../components/Targeting";
import Value from "../../components/Value";
import Words from "../../components/Words";
import { words } from "./words";

export default function Root() {
  const items = [
    {
      name: "Beazy",
      img: "images/deals/beazy.png",
      url: "https://www.beazy.co/",
    },
    {
      name: "Acorn",
      img: "images/deals/acorn.png",
      url: "https://www.acorn.me/",
    },
    {
      name: "Alectio",
      img: "images/deals/alectio.png",
      url: "https://alectio.com/",
    },
    {
      name: "Fargo",
      img: "images/deals/fargo.png",
      url: "https://fargowealth.com/en/",
    },
    {
      name: "Growrk",
      img: "images/deals/growrk.png",
      url: "https://growrk.com/",
    },
    {
      name: "Outro",
      img: "images/deals/outro.png",
      url: "https://www.outro.com/",
    },
    {
      name: "Odin",
      img: "images/deals/odin.png",
      url: "https://www.joinodin.com/",
    },
    { name: "MVPR", img: "images/deals/mvpr.png", url: "https://mvpr.io/" },
  ];

  return (
    <div id="wrapper">
      <Header transparent />
      <RevSlider />
      <Targeting />
      <Network />
      <Cheques />
      <Value />
      <div className="position-relative w-100 bg-white">
        <PortfolioCarousel items={items} />
        <Words words={words} />
      </div>
      <Pitch />
      <Footer />
    </div>
  );
}
