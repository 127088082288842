import { PopupButton } from "@typeform/embed-react";
import React from "react";

export default function Intro() {
  return (
    <div
      className="row align-content-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <div className="col-12 text-center pb-0">
        <h1
          className="fw-semibold lh-sm display-3 mt-5 mb-5"
          style={{
            letterSpacing: -1,
            color: "white",
            textShadow: "black 2px 2px",
          }}
        >
          We're for the journey.
        </h1>
        <p className=" fs-4 mb-5" style={{ textShadow: "black 1px 1px" }}>
          Investing in world-class teams that are solving big problems.
        </p>
        <div
          className="row justify-content-center gutter-20 mx-auto"
          style={{ maxWidth: 700 }}
        >
          <div className="col-sm-6 col-xl-5">
            <button
              data-scrollto="#targeting"
              className="button button-large button-shadow button-shadow-contrast border-contrast-900 bg-opacity-50 border border-width-2 text-contrast-900 h-text-light rounded m-0 w-100"
              data-offset="100"
              data-easing="easeInOutExpo"
              data-speed="1250"
              style={{ padding: "0.75rem 2rem" }}
            >
              <i
                className="uil uil-images"
                style={{ position: "relative", top: 1, marginRight: 5 }}
              ></i>{" "}
              Investor-startup fit
            </button>
          </div>
          <div className="col-sm-6 col-xl-5 mb-5">
            <PopupButton
              id="uVFKqJrN"
              className="button button-large button-shadow button-shadow-contrast button-border border-contrast-900 border-width-2 button-contrast-900 text-contrast-900 h-text-light rounded m-0 w-100"
              style={{ padding: "0.75rem 2rem" }}
            >
              <i className="bi-magic me-2"></i> Pitch us
            </PopupButton>
          </div>
        </div>
      </div>
    </div>
  );
}
