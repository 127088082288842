import React from "react";

interface Props {
  words: Array<string>;
}
export default function Words({ words }: Props) {
  return (
    <div className="position-absolute start-50 top-50 translate-middle z-2">
      <div className="px-3 bg-white rounded-6 border border-width-2 border-dark button-shadow button-shadow-dark button-shadow-nohover">
        <span
          className="text-rotater font-primary text-black fw-medium demos-text-rotator"
          data-separator="|"
          data-rotate="fadeIn"
          data-speed="50"
          data-backdelay="1500"
          data-typed="true"
          data-shuffle="true"
          data-loop="true"
          data-cursor="false"
        >
          &nbsp;
          <span className="t-rotate">{words.join("|")}</span>
          &nbsp;
        </span>
      </div>
    </div>
  );
}
