import { PopupButton } from "@typeform/embed-react";
import React from "react";

export default function Footer() {
  return (
    <footer id="footer" className="border-0 bg-white">
      <div
        id="copyrights"
        style={{
          background: "url('images/footer.svg') no-repeat top center",
          backgroundSize: "cover",
          paddingTop: 70,
        }}
      >
        <div className="container">
          <div className="row justify-content-between col-mb-30">
            <div className="col-12 col-lg-auto text-center text-lg-start fw-medium">
              Copyright © {new Date().getFullYear()} HITCHHIKER VENTURES PTE.
              LTD.
            </div>

            <div className="col-12 col-lg-auto text-center text-lg-end">
              <div className="copyrights-menu copyright-links">
                <PopupButton
                  style={{ background: "none", border: "none" }}
                  id="uVFKqJrN"
                >
                  Contact
                </PopupButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
