import React from "react";

export function redirectOnAuthError(error: Error) {
  console.log({ error });
}

export const useAsyncError = () => {
  const [_, setError] = React.useState();
  return React.useCallback(
    (e) => {
      setError(() => {
        console.log("setting");
        throw e;
      });
    },
    [setError]
  );
};
