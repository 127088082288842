import React from "react";

export default function Targeting() {
  return (
    <div
      id="targeting"
      className="section m-0 text-center "
      style={{ background: "#BDB39F" }}
    >
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <h3 className=" text-uppercase fw-bold mb-4">We target</h3>
            <h1 className=" fw-bold display-5" style={{ letterSpacing: -1 }}>
              Seed-stage teams who plan to build $50M+ revenue businesses in 5
              years.
            </h1>
            <p
              className="op-07 mx-auto px-5 fw-medium"
              style={{ fontSize: "20px" }}
            >
              (It’s never too early to talk to us, but if you’re raising Series
              A, it’s probably too late)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
