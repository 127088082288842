import React from "react";

export default function Value() {
  return (
    <section style={{ position: "relative", background: "rgb(239, 233, 226)" }}>
      <div className="container">
        <div className="row py-6 align-items-center">
          <div className="col-lg-5 mt-4 mt-lg-0">
            <img
              src="images/camper.jpeg"
              alt="Image"
              className="rounded-6 shadow border border-width border-width-5 m-4"
            />
          </div>
          <div className="col-lg-6 offset-lg-1">
            <h2 className="display-4 fw-bold">We can lead or follow.</h2>
            <p className="fw-medium">
              We aim to be Responsive (not Proactive) Shareholders. We don't
              seek board seats, weird veto rights, or monthly updates.
            </p>
            <p className="fw-medium">
              We'll have a quarterly meeting, and we'll spend as much time as
              you need when you call.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
