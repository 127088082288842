import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  query,
  where,
} from "@firebase/firestore";
import { keyBy } from "lodash";
import React from "react";
import { useParams } from "react-router";
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData,
} from "reactfire";
import { PollValue, VoteValue, WithId } from "../../../../functions/src/schema";
import Loader from "../../../components/Loader";
import VotesTable from "../../../components/VotesTable";
import VoteSummary from "../../../components/VoteSummary";
import { WithUser } from "../../../components/WithUser";
import { useMembers } from "../../../utils/members";

function Poll() {
  let { id } = useParams();
  const firestore = useFirestore();
  const members = useMembers();
  const pollRef = doc(firestore, "polls", id) as DocumentReference<
    WithId<PollValue>
  >;

  const membersByUid = keyBy(members, "uid");

  const { data: poll } = useFirestoreDocData<WithId<PollValue>>(pollRef, {
    idField: "id",
  });

  const { data: votes = [] } = useFirestoreCollectionData(
    query(
      collection(firestore, "votes") as CollectionReference<VoteValue>,
      where("pollId", "==", id),
      where("comment", "!=", null)
    )
  );

  return (
    <div style={{ paddingBottom: 100, minHeight: "100%" }}>
      <Loader visible={!poll} />
      <h1>{poll?.subject}</h1>
      <h3>Cutoff date: {poll?.deadline?.toDate()?.toDateString()}</h3>
      <h3>Quorum: {poll?.quorum}</h3>

      <div style={{ marginBottom: 20 }}>
        <div className="card">
          <div className="card-body">
            {poll ? (
              <VoteSummary
                votes={poll.votes}
                memberCount={members?.length || 0}
              />
            ) : null}
            {poll ? (
              <VotesTable
                poll={poll}
                votes={poll?.votes}
                founderCall={poll?.founderCall}
              />
            ) : null}
          </div>
        </div>
      </div>
      <h3>Comments</h3>

      <table className="table table-striped">
        <thead>
          <tr>
            <th>Member</th>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody>
          {votes.map((vote) => (
            <tr>
              <td>{membersByUid[vote.uid]?.email}</td>
              <td>{vote.comment}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default WithUser(Poll);
