import { map } from "lodash";
import React from "react";
import { PollValue, Votes, WithId } from "../../functions/src/schema";
import { formatVote } from "../utils/votes";

type Props = {
  poll: WithId<PollValue>;
  votes: Votes;
  founderCall: Record<string, boolean>;
} & Partial<
  React.DetailedHTMLProps<
    React.TableHTMLAttributes<HTMLTableElement>,
    HTMLTableElement
  >
>;

export default function VotesTable({
  poll,
  votes,
  founderCall,
  ...rest
}: Props) {
  return (
    <table className="table table-striped" {...rest}>
      <thead>
        <tr>
          <th>Member</th>
          <th>Vote</th>
          <th>Founder call</th>
        </tr>
      </thead>
      <tbody>
        {map(votes, (vote, email) => (
          <tr>
            <td>{email}</td>
            <td>{formatVote(vote)}</td>
            <td>{founderCall?.[email] ? "Yes" : ""}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
