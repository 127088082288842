import { isBefore } from "date-fns";
import { keys, pickBy, values } from "lodash";
import { PollValue, Votes } from "../../functions/src/schema";

export function filteringStage(poll: PollValue) {
  if (!poll.votes) return 0;
  return keys(pickBy(values(poll.votes))).length;
}

export function isPastDeadline(poll: PollValue) {
  return isBefore(poll.deadline?.toDate(), new Date());
}

export function votesSummary(votes: Votes) {
  const interested = keys(
    pickBy(votes, (vote) => vote === "interested")
  ).length;
  const notInterested = keys(
    pickBy(votes, (vote) => vote === "not-interested")
  ).length;
  const yes = keys(pickBy(votes, (vote) => vote === "yes")).length;

  return { interested, notInterested, yes };
}
