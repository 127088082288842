export function parseError(error: Error) {
  if (
    error.message.includes("auth/invalid-email") ||
    error.message.includes("auth/user-not-found")
  ) {
    return {
      email: "We can't find an account for that email address",
    };
  }
  if (error.message.includes("auth/wrong-password"))
    return {
      password: "is incorrect",
    };

  return { email: "Something went wrong" };
}
