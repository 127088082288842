import React, { useEffect } from "react";
import Intro from "../Intro";
import { initRevSlider } from "./init";

export default function RevSlider() {
  useEffect(() => {
    initRevSlider();
  }, []);

  return (
    <section
      id="slider"
      className="slider-element revslider-wrap slider-parallax min-vh-100 include-header"
    >
      <div className="slider-inner">
        <div
          className="rev_slider_wrapper fullscreen-container"
          data-alias="default-slider"
          style={{ padding: 0 }}
        >
          <div
            id="rev_slider_579_1"
            className="rev_slider fullscreenbanner"
            style={{ display: "none" }}
            data-version="5.1.4"
          >
            <ul>
              <li
                className="dark"
                data-transition="fade"
                data-slotamount="1"
                data-masterspeed="1000"
                data-thumb="images/slider/rev/main/s1-thumb.jpg"
                data-saveperformance="off"
                data-title="Welcome to Canvas"
              >
                <img
                  src="images/kelly-still.png"
                  alt="Image"
                  data-bgposition="center center"
                  data-bgfit="cover"
                  data-bgrepeat="no-repeat"
                  data-bgparallax="10"
                  className="rev-slidebg"
                  data-no-retina
                />
                <div
                  className="tp-fade fadeout fullscreenvideo rs-background-video-layer"
                  data-videoloop="loopandnoslidestop"
                  data-forcerewind="on"
                  data-volume="mute"
                  data-videowidth="100%"
                  data-videoheight="100%"
                  // Plz keep up to date with preload link in index.html
                  data-videomp4="https://firebasestorage.googleapis.com/v0/b/deals-7301a.appspot.com/o/kelly-compressed.mp4?alt=media&token=72880c3e-40f0-4c61-96cb-c3b03d8902eb"
                  data-videopreload="preload"
                  data-forcecover="1"
                  data-aspectratio="16:9"
                  data-autoplay="true"
                  data-autoplayonlyfirsttime="false"
                  data-loop="true"
                ></div>

                <Intro />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
