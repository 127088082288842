import React from "react";

export default function Cheques() {
  return (
    <div
      className="m-0 section text-center "
      style={{ background: "rgb(241, 199, 122)" }}
    >
      <div
        className="row text-center justify-content-center mb-0 mx-lg-auto"
        style={{ maxWidth: 1100 }}
      >
        <div className="col-sm-6 col-md-4 col-lg-1-5">
          <div className=" counter fs-1" style={{ lineHeight: 1.1 }}>
            25-100k
          </div>
          <h3>
            <span className="subtitle ls-0 nocolor h5 fw-medium">
              First cheque size
            </span>
          </h3>
        </div>

        <div className="col-sm-6 col-md-4 offset-md-2 col-lg-1-5">
          <div className=" counter  fs-1" style={{ lineHeight: 1.1 }}>
            250K
            <small className="ms-1">+</small>
          </div>
          <h3>
            <span className=" subtitle ls-0 nocolor h5 fw-medium">
              Follow on (Series A)
            </span>
          </h3>
        </div>
      </div>
      <p className="mx-auto mb-0" style={{ maxWidth: "50vw" }}>
        If there’s room in the round, we like to invite great angels and VCs
        from our network to co-invest with us.
      </p>
    </div>
  );
}
