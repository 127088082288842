import { useEffect, useRef } from "react";

export function useFunctionRun() {
  var isRun = useRef(false);

  useEffect(() => {
    if (!isRun.current) {
      (window as any).SEMICOLON.Hack.init();
      isRun.current = true;
    }
  }, []);
  return null;
}
