const W = window as any;

export function initRevSlider() {
  const revapi202 = W.jQuery("#rev_slider_579_1")
    .show()
    .revolution({
      sliderType: "standard",
      jsFileLocation: "include/rs-plugin/js/",
      sliderLayout: "fullscreen",
      dottedOverlay: "none",
      delay: 9000,
      responsiveLevels: [1140, 1024, 778, 480],
      visibilityLevels: [1140, 1024, 778, 480],
      gridwidth: [1140, 1024, 778, 480],
      gridheight: [728, 768, 960, 720],
      lazyType: "none",
      shadow: 0,
      spinner: "off",
      shuffle: "off",
      autoHeight: "off",
      fullScreenAutoWidth: "off",
      fullScreenAlignForce: "off",
      fullScreenOffsetContainer: "",
      fullScreenOffset: "",
      disableProgressBar: "on",
      hideThumbsOnMobile: "off",
      hideSliderAtLimit: 0,
      hideCaptionAtLimit: 0,
      hideAllCaptionAtLilmit: 0,
      debugMode: false,
      fallbacks: {
        simplifyAll: "off",
        disableFocusListener: false,
      },
      parallax: {
        type: "mouse",
        origo: "slidercenter",
        speed: 300,
        levels: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 49, 50, 51, 55],
      },
      navigation: {
        keyboardNavigation: "off",
        keyboard_direction: "horizontal",
        mouseScrollNavigation: "off",
        onHoverStop: "off",
        touch: {
          touchenabled: "on",
          swipe_threshold: 75,
          swipe_min_touches: 1,
          swipe_direction: "horizontal",
          drag_block_vertical: false,
        },
      },
    });

  revapi202.on("revolution.slide.onloaded", function (e) {
    setTimeout(function () {
      W.SEMICOLON.Base.sliderDimensions();
      W.sliderHeight = W.jQuery("#slider").outerHeight();
    }, 1000);
  });
}
