import {
  collection,
  CollectionReference,
  orderBy,
  query,
} from "@firebase/firestore";
import { keyBy } from "lodash";
import React from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { PollValue, VoteValue, WithId } from "../../../functions/src/schema";
import FullVotesTable from "../../components/FullVotesTable";
import Header from "../../components/Header";
import MembersTable from "../../components/MembersTable";
import PollsTable from "../../components/PollsTable";
import { WithUser } from "../../components/WithUser";
import { useMembers } from "../../utils/members";

function Dashboard() {
  const members = useMembers();
  const votesCollection = collection(
    useFirestore(),
    "votes"
  ) as CollectionReference<WithId<VoteValue>>;
  const pollsCollection = query(
    collection(useFirestore(), "polls") as CollectionReference<
      WithId<PollValue>
    >,
    orderBy("createdAt", "desc")
  );

  const { data: polls = [] } = useFirestoreCollectionData(pollsCollection, {
    idField: "id",
  });

  const { data: votes = [] } = useFirestoreCollectionData(
    query(votesCollection, orderBy("createdAt", "desc")),
    { idField: "id" }
  );

  const pollsById = keyBy(polls, "id");
  const membersByUid = keyBy(members, "uid");

  return (
    <div id="wrapper">
      <Header dark hideDashboardLink />
      <div className="container pt-5">
        <h3>Polls</h3>
        <PollsTable polls={polls} style={{ marginBottom: 100 }} />
        <h3>Members</h3>
        <MembersTable members={members} style={{ marginBottom: 100 }} />
        <h3>Votes</h3>
        <FullVotesTable
          membersByUid={membersByUid}
          pollsById={pollsById}
          votes={votes}
        />
      </div>
    </div>
  );
}

export default WithUser(Dashboard);
