import { User } from "@firebase/auth";
import React from "react";

type Props = {
  members: Array<User>;
} & Partial<
  React.DetailedHTMLProps<
    React.TableHTMLAttributes<HTMLTableElement>,
    HTMLTableElement
  >
>;

export default function MembersTable({ members, ...rest }: Props) {
  return (
    <table className="table table-striped" {...rest}>
      <thead>
        <tr>
          <th>Email</th>
          <th>Date added</th>
        </tr>
      </thead>
      <tbody>
        {members.map((member) => (
          <tr>
            <td>{member.email}</td>
            <td>{member.metadata.creationTime}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
