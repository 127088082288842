import { doc, DocumentReference } from "firebase/firestore";
import React, { useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useFirestore, useFirestoreDocDataOnce } from "reactfire";
import { PollValue } from "../../../functions/src/schema";
import Loader from "../../components/Loader";

export default function ConfirmScreen() {
  const params = new URLSearchParams(window.location.search);
  const pollId = params.get("pollId");
  const uid = params.get("uid");
  const vote = params.get("vote");
  const pollDoc = doc(
    useFirestore(),
    `polls/${pollId}`
  ) as DocumentReference<PollValue>;
  const [captcha, setCaptcha] = React.useState<string | null>();
  const { data: poll, status } = useFirestoreDocDataOnce(pollDoc);
  // TODO: would be nice to pull this out somehwere

  if (status === "success" && !poll) {
    window.location.href = "/404";
  }

  if (!pollId || !uid || !vote) {
    window.location.href = "/404";
  }

  useEffect(() => {
    const url = `https://us-central1-deals-7301a.cloudfunctions.net/onVoteReceived?pollId=${pollId}&uid=${uid}&vote=${vote}&captcha=${captcha}`;
    if (captcha) window.location.href = url;
  }, [captcha, uid, vote, pollId]);

  if (!poll) return <Loader visible />;

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ textAlign: "center", maxWidth: 600 }}>
        <img
          alt="Speedy Gonzales"
          src="https://firebasestorage.googleapis.com/v0/b/deals-7301a.appspot.com/o/speedy.gif?alt=media&token=01169d9e-ccb3-4c6f-a32a-5001f16bb2a0"
        />
        <h1 style={{ marginTop: 20 }}>Hey there, speedy!</h1>
        <p>
          We received your vote very quickly, so we need to confirm that you're
          human. This is because some email clients auto-click links in emails
          🤖
        </p>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <ReCAPTCHA
            onChange={setCaptcha}
            sitekey="6LeSliMnAAAAAEQHBraI7-Mmo3GregrWdu-L041H"
          />
        </div>
      </div>
    </div>
  );
}
