import React, { ErrorInfo, ReactElement } from "react";
interface ErrorBoundaryState {
  hasError: boolean;
  errorMessage: string;
}
interface ErrorboundaryProps {
  children: ReactElement;
}
export class ErrorBoundaries extends React.Component<
  ErrorboundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorboundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: "",
    };
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log("catching, baby");
    this.setState({ hasError: true });
    if (error) this.setState({ errorMessage: error.message });
  }
  render(): React.ReactNode {
    if (this.state?.hasError) {
      return (
        <div className="divClass">
          <p>
            <h3>ErrorBoundaries</h3>
          </p>
          {this.state.errorMessage}
        </div>
      );
    }
    return this.props.children;
  }
}
