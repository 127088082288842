import { User } from "@firebase/auth";
import React, { useEffect, useState } from "react";
import { auth } from "../config/firebase";

interface Props {
  transparent?: boolean;
  dark?: boolean;
  hideDashboardLink?: boolean;
}
export default function Header({
  transparent,
  dark,
  hideDashboardLink,
}: Props) {
  const [user, setUser] = useState<User | null>(null);

  function handleSignOutButtonClick() {
    auth.signOut();
    window.location.href = "/login";
  }

  // TODO: should probably create a global auth context
  useEffect(() => {
    return auth.onAuthStateChanged(setUser);
  }, []);

  return (
    <header
      id="header"
      className={`full-header ${dark ? "dark" : null} ${
        transparent ? "transparent-header" : null
      }`}
    >
      <div id="header-wrap">
        <div className="container">
          <div className="header-row">
            <div id="logo">
              <a href="/">
                <img
                  className="logo-default"
                  srcSet="/images/logo.png, /images/logo@2x.png 2x"
                  src="/images/logo@2x.png"
                />
                <img
                  className="logo-dark"
                  srcSet="/images/logo.png, /images/logo@2x.png 2x"
                  src="/images/logo@2x.png"
                />
              </a>
            </div>
            <nav className="primary-menu">
              <ul className="menu-container">
                {user ? (
                  <>
                    {hideDashboardLink ? null : (
                      <li className="menu-item">
                        <a
                          className="menu-link"
                          style={{ color: "white" }}
                          href="/dashboard"
                        >
                          Investor dashboard
                        </a>
                      </li>
                    )}
                    <li className="menu-item">
                      <a
                        className="menu-link"
                        style={{ color: "white" }}
                        onClick={handleSignOutButtonClick}
                      >
                        Sign out
                      </a>
                    </li>
                  </>
                ) : (
                  <li className="menu-item">
                    <a
                      className="menu-link"
                      href="login"
                      style={{ color: "white" }}
                    >
                      Investor Login
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="header-wrap-clone"></div>
    </header>
  );
}
