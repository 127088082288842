import React from "react";

export default function Network() {
  return (
    <section
      id="slider"
      className="slider-element py-6 block-hero-6"
      style={{
        backgroundImage:
          "radial-gradient(circle at top right, #DDD 0%, #DDD 28%, #BDB39F 28%, #BDB39F 45%,#F1C77A 45%, #F1C77A 63%,#EFE9E2 63%, #EFE9E2 100%)",
      }}
    >
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-md-6">
            <h2 className="display-3 fw-bold" style={{ color: "#121212" }}>
              Our main value add will be our network.
            </h2>
            <p className="lead text-black-75 fw-medium">
              We’d love to introduce you to customers, investors, experts,
              potential hires, and other founders who’ve problem-solved in areas
              where you’re struggling.
            </p>
            <p className="lead text-black-75 fw-medium">
              (If we can’t make a useful introduction during our first
              conversations, we may not be a good fit.)
            </p>
          </div>

          <div className="col-md-5 mt-5 mt-md-0">
            <img
              src="images/shoes.jpeg"
              alt="image"
              className="rounded-6 shadow border border-width border-width-5"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
