import { signInWithCustomToken, User } from "firebase/auth";
import { doc, DocumentReference, updateDoc } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { useDebounce } from "use-lodash-debounce";
import { VoteValue } from "../../../functions/src/schema";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import PollSubject from "../../components/PollSubject";
import { auth } from "../../config/firebase";
import useChangeEffect from "../../utils/changeEffect";
import { formatVote } from "../../utils/votes";

export default function Thankyou() {
  const params = new URLSearchParams(window.location.search);
  const voteId = params.get("voteId");
  const [comment, setComment] = useState<string>();
  const [founderCall, setFounderCall] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const textareaRef = useRef();
  const founderCallRef = useRef();
  const voteDoc = doc(
    useFirestore(),
    `votes/${voteId}`
  ) as DocumentReference<VoteValue>;
  const authToken = params.get("authToken");

  useEffect(() => {
    auth.onAuthStateChanged(setUser);
  }, []);

  useEffect(() => {
    if (authToken) {
      signInWithCustomToken(auth, authToken)
        .then((userCredential) => {
          // The user is now signed in
          console.log("User signed in:", userCredential.user);
        })
        .catch((error) => {
          console.error("Error signing in with custom token:", error);
        });
    }
  }, [authToken]);
  // Sign in the user using the custom token

  const { data: vote } = useFirestoreDocData(voteDoc);

  const debouncedComment = useDebounce(comment, 500, { trailing: true });

  function getHeading() {
    if (!vote) return "Loading...";
    switch (vote.vote) {
      case "yes":
        return "Thanks for voting!";
      case "not-interested":
        return "Not interested - got it. Thanks!";
      case "interested":
        return "Got it, we’ll keep you posted!";
    }
  }
  function getCommentPlaceholder() {
    if (!vote) return "Loading...";
    switch (vote.vote) {
      case "yes":
        return "Leave an optional comment";
      case "interested":
        return "What else would you like to know? What questions should we ask the founders?";
      case "not-interested":
        return "Optional - Any comments / reasons you want to share with the group?";
    }
  }

  useChangeEffect(() => {
    if (!user) return;
    if (comment)
      updateDoc(voteDoc, { comment: debouncedComment }).then(() => {
        (window as any).SEMICOLON.Modules.notifications(textareaRef.current);
      });
  }, [debouncedComment, user]);

  useChangeEffect(() => {
    updateDoc(voteDoc, { founderCall }).then(() => {
      (window as any).SEMICOLON.Modules.notifications(founderCallRef.current);
    });
  }, [founderCall]);

  useEffect(() => {
    if (vote) (window as any).SEMICOLON.Modules.shapeDivider();
  }, [vote]);

  return (
    <div id="wrapper">
      <Loader visible={!vote} />
      <Header dark />
      {vote ? (
        <section
          id="slider"
          className="slider-element"
          style={{
            backgroundColor: "#ECF4F1",
            padding: "100px 0",
            minHeight: "100vh",
          }}
        >
          <div
            className="shape-divider"
            data-shape="wave-6"
            data-height="350"
          ></div>

          <div className="container mw-sm text-center" style={{ zIndex: 3 }}>
            <h3 className="display-4 fw-bold mb-3">{getHeading()}</h3>
            <div className="row">
              <div className="col-sm-6">
                {vote ? <PollSubject id={vote.pollId} /> : null}
              </div>
              <div className="col-sm-6">
                <h4>Your vote: {vote ? formatVote(vote.vote) : "..."}</h4>
              </div>
            </div>
            <div className="clear"></div>
            <textarea
              ref={textareaRef}
              data-bs
              data-notify-type="success"
              data-notify-msg="<i class='bi-check-circle-fill me-1'></i> Thanks - we've recorded your comment"
              id="comment-field"
              className="form-control"
              placeholder={getCommentPlaceholder()}
              rows={3}
              value={comment}
              onChange={(event) => setComment(event.target.value)}
              style={{ marginBottom: 20 }}
            ></textarea>

            {vote?.vote === "interested" ? (
              <label>
                <input
                  checked={founderCall}
                  onChange={() => setFounderCall((e) => !e)}
                  type="checkbox"
                  ref={founderCallRef}
                  style={{ marginRight: 10 }}
                  data-notify-type="success"
                  data-notify-msg="<i class='bi-check-circle-fill me-1'></i> Ok - we've recorded your preference"
                ></input>
                I would like to be included in the next call with the founder
              </label>
            ) : null}
          </div>
        </section>
      ) : null}
    </div>
  );
}
