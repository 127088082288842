import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/Header";
import { WithUser } from "../../components/WithUser";

function Deals() {
  return (
    <div id="wrapper">
      <Header dark />
      <div className="container pt-4">
        <a href="/dashboard">Back to dashboard</a>
        <Outlet />
      </div>
    </div>
  );
}

export default WithUser(Deals);
