import React from "react";

interface Props {
  items: Array<{ img: string; name: string; url: string }>;
}
export default function PortfolioCarousel({ items }: Props) {
  return (
    <div className="overflow-hidden slider-element swiper_wrapper intro-swiper-marquee customjs h-auto">
      <div className="mt-5 slider-inner viewport-detect">
        <div className="swiper-container">
          <div className="swiper-wrapper">
            {items.map((item, index) => (
              <div
                key={index}
                className={`swiper-slide ${index % 2 === 0 && "mt-2"} ${
                  index % 3 === 0 && "mb-2"
                }`}
              >
                <div className="row g-4 mb-5">
                  <div className="col-12">
                    <div className="grid-inner">
                      <a href={item.url} target="_blank">
                        <img
                          width="400"
                          height="259"
                          src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 221 159'%3E%3C/svg%3E"
                          data-src={item.img}
                          alt="Skincare Demo"
                          className="rounded-5 lazy"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
