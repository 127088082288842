import React from "react";

export default function Unsubscribe() {
  return (
    <div
      style={{
        background: "black",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ textAlign: "center", maxWidth: 600 }}>
        <img src="https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExZ2FhbHU5cmRmZDc5amV4ZjZyYnFuNzA1YTY0dGQzMWVsb2xhZnJjMiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/111ebonMs90YLu/giphy.gif" />
        <h1 style={{ marginTop: 20, color: "#dadada" }}>
          Ok - we've recorded your preference for this type of email.
        </h1>
        <p style={{ color: "#dadada" }}>
          Bear in mind we'll still email you with other important
          communications, such as deal emails and legal updates. Too much email?
          Write to us at admin@hitchhiker.vc.
        </p>

        <a
          href="/"
          style={{ color: "#dadada" }}
          className="btn btn-lg text-white bg-secondary d-block w-100 m-0"
        >
          I want back in →
        </a>
      </div>
    </div>
  );
}
