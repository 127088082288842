import React from "react";
import { Votes } from "../../functions/src/schema";
import { votesSummary } from "../utils/polls";

interface Props {
  votes: Votes;
  memberCount: number;
}
export default function VoteSummary({ votes, memberCount }: Props) {
  const { interested, notInterested, yes } = votesSummary(votes);
  const waiting = Math.max(memberCount - yes - interested - notInterested, 0);

  return (
    <div className="row col-mb-50">
      <div className="col-sm-6 col-lg-3 text-center">
        <div className="counter counter-large" style={{ color: "#4cc73c" }}>
          <span
            data-from="100"
            data-to="8465"
            data-refresh-interval="50"
            data-speed="2000"
          >
            {interested}
          </span>
        </div>
        <h5>Interested</h5>
      </div>

      <div className="col-sm-6 col-lg-3 text-center">
        <div className="counter counter-large" style={{ color: "#e74c3c" }}>
          <span
            data-from="100"
            data-to="56841"
            data-refresh-interval="50"
            data-speed="2500"
          >
            {notInterested}
          </span>
        </div>
        <h5>Not interested</h5>
      </div>

      <div className="col-sm-6 col-lg-3 text-center">
        <div className="counter counter-large">
          <span
            data-from="100"
            data-to="2154"
            data-refresh-interval="50"
            data-speed="3500"
          >
            {yes}
          </span>
        </div>
        <h5>Yes</h5>
      </div>

      <div className="col-sm-6 col-lg-3 text-center">
        <div className="counter counter-large" style={{ color: "#aaa" }}>
          <span
            data-from="100"
            data-to="2154"
            data-refresh-interval="50"
            data-speed="3500"
          >
            {waiting}
          </span>
        </div>
        <h5>Waiting</h5>
      </div>
    </div>
  );
}
