import { PopupButton } from "@typeform/embed-react";
import React from "react";

export default function Pitch() {
  return (
    <section className="bg-white pt-6 pb-4" style={{ position: "relative" }}>
      <div className="container">
        <div className="row">
          <div className="offset-md-3 col-md-3 text-center">
            <p className="lead fs-4 mb-5">Ready?</p>
          </div>
          <div className="col-md-4 ">
            <PopupButton
              id="uVFKqJrN"
              className="button button-large button-shadow button-shadow-contrast button-border border-contrast-900 border-width-2 button-contrast-900 text-contrast-900 h-text-light rounded m-0 w-100"
              style={{ padding: "0.75rem 2rem" }}
            >
              <i className="bi-magic me-2"></i> Pitch us
            </PopupButton>
          </div>
        </div>
      </div>
    </section>
  );
}
