import { doc, DocumentReference } from "firebase/firestore";
import React from "react";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { PollValue } from "../../functions/src/schema";

export default function PollSubject({ id }) {
  const { data: poll } = useFirestoreDocData(
    doc(useFirestore(), `polls/${id}`) as DocumentReference<PollValue>
  );
  return <h4>{poll?.subject}</h4>;
}
