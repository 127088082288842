import React from "react";
import { useSigninCheck } from "reactfire";
import Loader from "./Loader";

export function WithUser<P>(Component: React.ComponentType<P>) {
  return function ComponentWithUser(props: P) {
    const { status, data: signInCheckResult } = useSigninCheck();

    if (status === "loading") {
      return <Loader visible />;
    }

    if (!signInCheckResult.signedIn) {
      window.location.href = "/login";
      return <div>Redirecting...</div>;
    }

    return <Component {...props} />;
  };
}
