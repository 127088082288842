import { signInWithEmailAndPassword } from "@firebase/auth";
import React, { FormEvent, useEffect, useState } from "react";
import { auth } from "../config/firebase";
import { parseError } from "../utils/firebase";

import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export default function LoginScreen() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<{ email?: string; password?: string }>({
    email: null,
    password: null,
  });

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        window.location.href = "dashboard";
      }
    });
  }, []);

  function handleSubmit(event: FormEvent) {
    if (event) event.stopPropagation();
    event.preventDefault();

    signInWithEmailAndPassword(auth, email, password).catch((error) => {
      setErrors(parseError(error));
    });
  }

  function handleForgotPasswordClick() {
    const email = prompt("Enter your email address");
    const auth = getAuth();

    sendPasswordResetEmail(auth, email)
      .then(() => {
        alert("Check your inbox for a password reset email!");
      })
      .catch((error) => {
        alert("Sorry, that didn't work: " + error.message);
      });
  }

  return (
    <section id="content">
      <div className="content-wrap py-0">
        <div
          className="section bg-light p-0 m-0 h-100 position-absolute"
          style={{
            background: "url(images/country-road.jpeg)",
            backgroundSize: "cover",
          }}
        ></div>

        <div className="section bg-transparent min-vh-100 p-0 m-0 d-flex">
          <div className="vertical-middle">
            <div className="container py-4">
              <div className="text-center">
                <a href="/">
                  <img
                    src="images/logo@2x.png"
                    alt="Canvas Logo"
                    style={{ height: 100 }}
                  />
                </a>
              </div>

              <div
                className="card mx-auto rounded-4 border-0 shadow-lg mt-5"
                style={{ maxWidth: 500 }}
              >
                <div className="card-body p-5 ">
                  <form
                    id="login-form"
                    name="login-form"
                    className="mb-0"
                    onSubmit={handleSubmit}
                  >
                    <h1 className="fs-4 fw-semibold text-center mb-0">
                      Investor Login
                    </h1>

                    <div className="row">
                      <div className="col-12 form-group mb-4">
                        <label htmlFor="login-form-username">Email</label>
                        <input
                          type="text"
                          id="login-form-username"
                          name="login-form-username"
                          className={`form-control not-dark ${
                            errors.email ? "error show-error-msg" : null
                          }`}
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                        />
                        <label className="error">{errors.email}</label>
                      </div>

                      <div className="col-12 form-group mb-4">
                        <div className="d-flex justify-content-between">
                          <label htmlFor="login-form-password">Password</label>
                          <a
                            onClick={handleForgotPasswordClick}
                            href="#"
                            className="fw-semibold text-smaller"
                          >
                            Forgot Password?
                          </a>
                        </div>
                        <input
                          type="password"
                          id="login-form-password"
                          name="login-form-password"
                          value={password}
                          onChange={(event) => setPassword(event.target.value)}
                          className={`form-control not-dark ${
                            errors.password ? "error show-error-msg" : null
                          }`}
                        />
                        <label className="error">{errors.password}</label>
                      </div>

                      <div className="col-12 form-group mb-0">
                        <button
                          className="btn btn-lg text-white bg-black h-bg-color d-block w-100 m-0"
                          id="login-form-submit"
                          name="login-form-submit"
                          value="login"
                          type="submit"
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
