import { User } from "@firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { useAsyncError } from "./auth";

const functions = getFunctions();
const listUsers = httpsCallable(functions, "listUsers");

export function useMembers() {
  const throwError = useAsyncError();
  const [members, setMembers] = useState<Array<User>>([]);

  useEffect(() => {
    listUsers()
      .then(({ data: { users } }: any) => setMembers(users))
      .catch(throwError);
  }, [throwError]);
  return members;
}
