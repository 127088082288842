import { getFirestore } from "@firebase/firestore";
import { getAuth } from "firebase/auth";
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  AuthProvider,
  FirebaseAppProvider,
  FirestoreProvider,
  useFirebaseApp,
} from "reactfire";
import { ErrorBoundaries } from "./components/ErrorBoundary";
import { firebaseConfig } from "./config/firebase";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import ConfirmScreen from "./screens/Confirm";
import Dashboard from "./screens/Dashboard";
import ErrorPage from "./screens/ErrorPage";
import LoginScreen from "./screens/Login";
import Polls from "./screens/Polls";
import Poll from "./screens/Polls/screens/Poll";
import Root from "./screens/Root";
import Thankyou from "./screens/Thankyou";
import Unsubscribe from "./screens/Unsubscribe";
import { useFunctionRun } from "./utils/run";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
  },
  {
    path: "/login",
    element: <LoginScreen />,
  },
  {
    path: "/confirm",
    element: <ConfirmScreen />,
  },
  {
    path: "/dashboard",
    element: (
      <ErrorBoundaries>
        <Dashboard />
      </ErrorBoundaries>
    ),
  },
  {
    path: "polls",
    element: <Polls />,
    children: [
      {
        path: ":id",
        element: <Poll />,
      },
    ],
  },
  {
    path: "/thankyou",
    element: <Thankyou />,
  },
  {
    path: "/unsubscribe",
    element: <Unsubscribe />,
  },
  {
    path: "/too-late",
    element: (
      <ErrorPage error="Sorry, your vote was received after the deadline" />
    ),
  },
  {
    path: "/404",
    element: (
      <ErrorPage
        error="404 - page not found"
        subheading="That's all we know."
      />
    ),
  },
]);

function App() {
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const firestoreInstance = getFirestore(app);

  useFunctionRun();

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <AuthProvider sdk={auth}>
        <RouterProvider router={router} />
      </AuthProvider>
    </FirestoreProvider>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <App />
    </FirebaseAppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
