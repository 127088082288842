import React from "react";
import { PollValue, WithId } from "../../functions/src/schema";
import { votesSummary } from "../utils/polls";

type Props = {
  polls: Array<WithId<PollValue>>;
} & Partial<
  React.DetailedHTMLProps<
    React.TableHTMLAttributes<HTMLTableElement>,
    HTMLTableElement
  >
>;

export default function DealTable({ polls, ...rest }: Props) {
  return (
    <table className="table table-striped" {...rest}>
      <thead>
        <tr>
          <th>Subject</th>
          <th>Date added</th>
          <th>EOI cutoff date</th>
          <th>Interested</th>
          <th>Not interested</th>
          <th>Yes</th>
        </tr>
      </thead>
      <tbody>
        {polls.map((poll) => {
          const summary = votesSummary(poll.votes);
          return (
            <tr>
              <td>
                <a href={`/polls/${poll.id}`}>{poll.subject}</a>
              </td>
              <td>{poll.createdAt.toDate().toDateString()}</td>
              <td>{poll.deadline.toDate().toDateString()}</td>
              <td>{summary.interested}</td>
              <td>{summary.notInterested}</td>
              <td>{summary.yes}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
