import { format } from "date-fns";
import { User } from "firebase/auth";
import React from "react";
import { PollValue, VoteValue } from "../../functions/src/schema";
import { formatVote } from "../utils/votes";

interface Props {
  votes: Array<VoteValue>;
  pollsById: Record<string, PollValue>;
  membersByUid: Record<string, User>;
}

export default function AllVotesTable({
  membersByUid,
  votes,
  pollsById,
}: Props) {
  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th>Member</th>
          <th>Recorded at</th>
          <th>Deal</th>
          <th>Vote</th>
          <th>Comment</th>
          <th>Founder call</th>
        </tr>
      </thead>
      <tbody>
        {votes.map((vote) => (
          <tr>
            <td>{membersByUid[vote.uid]?.email}</td>
            <td>{format(vote.createdAt.toDate(), "dd MMM yyyy HH:mm:ss")}</td>
            <td>
              {(vote.pollId && pollsById[vote.pollId]?.subject) || "UNKNOWN"}
            </td>
            <td>{formatVote(vote.vote)}</td>
            <td>{vote.comment}</td>
            <td>{vote.founderCall ? "Yes" : "No"}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
