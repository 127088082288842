import React, { useEffect } from "react";

export default function Loader({ visible }: { visible: boolean }) {
  const [show, setShow] = React.useState(true);

  useEffect(() => {
    setTimeout(() => setShow(visible), 1000);
  }, [visible]);

  if (!show) return null;

  return (
    <div
      className={["loader", visible ? "loader-visible" : undefined].join(" ")}
      style={{
        left: 0,
        top: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgb(236, 244, 241)",
        position: "absolute",
        zIndex: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src="https://firebasestorage.googleapis.com/v0/b/deals-7301a.appspot.com/o/loader.svg?alt=media&token=a1998701-4652-4691-a050-da9e99a9603d"
        alt="Moving car"
        width="700"
        height="400"
      ></img>
    </div>
  );
}
